import React from 'react';
import { httpPost } from '../../http';
import { message, Modal, Input, Form, Select } from 'antd';

const FormItem = Form.Item;
const { Option } = Select;


class AddFormContainer extends React.Component {
  state = {};
  render() {
    const { modalVisibleAdd, form, modalTitleAdd, handleModalVisibleAdd, getDeviceList, initData } = this.props;
    const handleOk = () => {
      form.validateFields((err, fieldsValue) => {
        if (err) return;
        form.resetFields();
        let submitValue = {
          id: initData.id,
          ...fieldsValue
        };
        if (!initData.id) {
          httpPost("/api/v1/device/create", JSON.stringify(submitValue))
            .then(res => {
              return res.json();
            })
            .then(data => {
              
              if (data.code === 0) {
                handleModalVisibleAdd();
                getDeviceList();
              } else {
                message.error(data.msg);
              }
            })
            .catch(err => {
              message.error('Interface error: add_device');
            }
            )
        } else {
          httpPost("/api/v1/device/update", JSON.stringify(submitValue))
            .then(res => {
              return res.json();
            })
            .then(data => {
              if (data.code === 0) {
                handleModalVisibleAdd();
                getDeviceList();
              } else {
                message.error(data.msg);
              }
            })
            .catch(err => {
              message.error('Interface error: update_device');
            }
            )
        }
      });
    };
    
    return (
      <Modal
        destroyOnClose
        title={modalTitleAdd}
        visible={modalVisibleAdd}
        width={400}
        okText="Save"
        onOk={handleOk}
        onCancel={() => handleModalVisibleAdd()}

      >
        <Form layout="vertical" hideRequiredMark>
          <FormItem label='Name'>
            {form.getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: 'Please input Name.',
                },
              ],
              initialValue: initData.name,
            })(<Input disabled={initData.user_id ? true : false} />)}
          </FormItem>
          <FormItem label='Device'>
            {form.getFieldDecorator('device_id', {
              rules: [
                {
                  required: true,
                  message: 'Please input device id.',
                },
              ],
              initialValue: initData.device_id,
            })(<Input />)}
          </FormItem>
          
          <FormItem label='Type'>
            {form.getFieldDecorator('type', {
              rules: [
                {
                  required: true,
                  message: 'Please input type.',
                },
              ],
              initialValue: initData.type,
            })(<Input />)}
          </FormItem>
          <FormItem label='company'>
            {form.getFieldDecorator('company_name', {
              rules: [
                {
                  required: true,
                  message: 'Please input company.',
                },
              ],
              initialValue: initData.company_name,
            })(<Input />)}
          </FormItem>
        </Form>
      </Modal>
    )
  }
}

const AddForm = Form.create()(AddFormContainer);
export default AddForm;