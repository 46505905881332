import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import { Descriptions, Divider, Table, message } from 'antd';

import './admin.css';

import { httpGet } from "http/index.js";

class Admin extends React.Component {
  state = {
    dataSource: []
  }
  
  componentDidMount() {
    this.getCompanyInstances();

    this.intervalID = setInterval(this.getCompanyInstances, 60*1000);
  }

  componentWillUnmount() {
    this.intervalID && clearInterval(this.intervalID);
  }

  render() {
    const columns = [
      {
        title: 'Company',
        dataIndex: 'CompanyName',
        key: 'CompanyName',
      },
      {
        title: 'Name',
        dataIndex: 'Name',
        key: 'Name',
      },
      {
        title: 'Device ID',
        dataIndex: 'DeviceId',
        key: 'DeviceId',
      },
      {
        title: 'Type',
        dataIndex: 'Type',
        key: 'Type',
      },
      {
        title: 'Temperature',
        key: 'Temperature',
        align: 'center',
        render: record => (
          <span className="wrappedStatus">
            {record.Temperature + "°"}
            &nbsp;&nbsp;&nbsp;
            {record.TemperatureActive === true ? <b className="online"></b> : <b className="offline"></b>}
          </span>
        )
      },
      {
        title: 'Humidity',
        key: 'Humidity',
        align: 'center',
        render: record => (
          <span className="wrappedStatus">
            {record.Humidity + "%"}
            &nbsp;&nbsp;&nbsp;
            {record.HumidityActive === true ? <b className="online"></b> : <b className="offline"></b>}
          </span>
        )
      },
      {
        title: 'Methane',
        key: 'Methane',
        align: 'center',
        render: record => (
          <span className="wrappedStatus">
            {record.Methane}
            &nbsp;&nbsp;&nbsp;
            {record.MethaneActive === true ? <b className="online"></b> : <b className="offline"></b>}
          </span>
        )
      },
      {
        title: 'Location',
        dataIndex: 'GpsActive',
        key: 'GpsActive',
        align: 'center',
        render: data => <span className="wrappedStatus">{data === true ? <b className="online"></b> : <b className="offline"></b>}</span>
      },
      {
        title: 'Operation',
        align: 'center',
        render: record => <span className="wrappedStatus"> <Link to={"/chart/" + record.DeviceId}>Chart</Link> {record.Type === "lorry" ? (<span><Divider type="vertical" /> <Link to={"/map/" + record.DeviceId}>Map</Link></span>) : ""}</span>
      },
    ];
    return (
      <div className='home' key="home">
        <Descriptions title="List of Products"></Descriptions>

        <Table rowKey={record => record.DeviceId} dataSource={this.state.dataSource} columns={columns} pagination={{ pageSize: 20 }} />
      </div>
    );
  };

  getCompanyInstances = () => {
    httpGet("/api/v1/instance/get_all_instances")
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (data.code === 0) {
          this.setState({
            dataSource: data.data,
          })
        } else {
          message.error('Interface error:get_all_instances');
        }
      })
      .catch(err => {
        message.error('Interface error:get_all_instances');
      }
      )
  }

};

export default withRouter(Admin);