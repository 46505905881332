import React, { Component } from 'react';

import { Form, Input, Button, Descriptions, Row, Col, message } from 'antd';

import { httpGet, httpPost } from "http/index.js";
import "./setting.css"

const FormItem = Form.Item;

export class ThresholdForm extends Component {
  state = {
    lorryTemp: "",
    lorryHumidity: "",
    lorryMethane: "",
    houseTemp: "",
    houseHumidity: "",
    houseMethane: "",
  }

  componentDidMount() {
    this.getThreshold();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const initData = this.state;
    return (


      <div className="setThreshold">
        <hr style={{ width: '96%' }} />
        <Descriptions style={{ marginLeft: 25 }}>
          <Descriptions.Item>Alert Threshold</Descriptions.Item>
        </Descriptions>
        <Form onSubmit={this.submitThreshold}>

          <Row style={{ textAlign: 'center', marginBottom: 10 }}>
            <Col span={2}></Col>
            <Col span={2}>Temperature</Col>
            <Col span={2}>Humidity</Col>
            <Col span={2}>Methane</Col>
          </Row>

          <Row style={{ textAlign: 'center' }}>
            <Col span={2} style={{ textAlign: 'right' }}>Lorry:</Col>
            <Col span={2} >
              <FormItem>
                {getFieldDecorator('lorryTemp', {
                  rules: [
                    {
                      required: true,
                      message: 'Veuillez saisir le numéro.',
                    },
                    {
                      pattern: new RegExp('^[0-9]*$'),
                      message: 'Veuillez saisir le numéro.',
                    }
                  ],
                  initialValue: initData.lorryTemp,
                })(<Input style={{ width: 60 }} />)}
              </FormItem>
            </Col>
            <Col span={2} >
              <FormItem>
                {getFieldDecorator('lorryHumidity', {
                  rules: [
                    {
                      required: true,
                      message: 'Veuillez saisir le numéro.',
                    },
                    {
                      pattern: new RegExp('^[0-9]*$'),
                      message: 'Veuillez saisir le numéro.',
                    }
                  ],
                  initialValue: initData.lorryHumidity,
                })(<Input style={{ width: 60 }} />)}
              </FormItem>
            </Col>
            <Col span={2} >
              <FormItem>
                {getFieldDecorator('lorryMethane', {
                  rules: [
                    {
                      required: true,
                      message: 'Veuillez saisir le numéro.',
                    },
                    {
                      pattern: new RegExp('^[0-9]*$'),
                      message: 'Veuillez saisir le numéro.',
                    }
                  ],
                  initialValue: initData.lorryMethane,
                })(<Input style={{ width: 60 }} />)}
              </FormItem>
            </Col>
          </Row>

          <Row style={{ textAlign: 'center' }}>
            <Col span={2} style={{ textAlign: 'right' }}>Warehouse:</Col>
            <Col span={2} >
              <FormItem>
                {getFieldDecorator('houseTemp', {
                  rules: [
                    {
                      required: true,
                      message: 'Veuillez saisir le numéro.',
                    },
                    {
                      pattern: new RegExp('^[0-9]*$'),
                      message: 'Veuillez saisir le numéro.',
                    }
                  ],
                  initialValue: initData.houseTemp,
                })(<Input style={{ width: 60 }} />)}
              </FormItem>
            </Col>
            <Col span={2} >
              <FormItem>
                {getFieldDecorator('houseHumidity', {
                  rules: [
                    {
                      required: true,
                      message: 'Veuillez saisir le numéro.',
                    },
                    {
                      pattern: new RegExp('^[0-9]*$'),
                      message: 'Veuillez saisir le numéro.',
                    }
                  ],
                  initialValue: initData.houseHumidity,
                })(<Input style={{ width: 60 }} />)}
              </FormItem>
            </Col>
            <Col span={2} >
              <FormItem>
                {getFieldDecorator('houseMethane', {
                  rules: [
                    {
                      required: true,
                      message: 'Veuillez saisir le numéro.',
                    },
                    {
                      pattern: new RegExp('^[0-9]*$'),
                      message: 'Veuillez saisir le numéro.',
                    }
                  ],
                  initialValue: initData.houseMethane,
                })(<Input style={{ width: 60 }} />)}
              </FormItem>
            </Col>

            <Col span={3}>
              <FormItem>
                <Button type="primary" htmlType="submit" >
                  Save Threshold
                </Button>
              </FormItem>
            </Col>
          </Row>


        </Form>
      </div>
    )
  }



  getThreshold = () => {
    httpGet("/api/v1/instance/get_threshold")
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (data.code === 0) {
          const lorry = data.data.Lorry;
          const house = data.data.Warehouse;
          this.setState({
            lorryTemp: lorry.Temperature,
            lorryHumidity: lorry.Humidity,
            lorryMethane: lorry.Methane,
            houseTemp: house.Temperature,
            houseHumidity: house.Humidity,
            houseMethane: house.Methane
          })
        } else {
          message.error('Interface error: get_threshold');
        }
      })
      .catch(err => {
        message.error('Interface error: get_threshold');
      })
  }



  submitThreshold = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) return;
      let submitValue = {
        Lorry: {
          Temperature: fieldsValue.lorryTemp,
          Humidity: fieldsValue.lorryHumidity,
          Methane: fieldsValue.lorryMethane
        },
        Warehouse: {
          Temperature: fieldsValue.houseTemp,
          Humidity: fieldsValue.houseHumidity,
          Methane: fieldsValue.houseMethane
        }
      };
      httpPost("/api/v1/instance/set_threshold", JSON.stringify(submitValue))
        .then(res => {
          return res.json();
        })
        .then(data => {
          if (data.code === 0) {
            this.getThreshold();
            message.success('Success.');
          } else {
            message.error(data.msg);
          }
        })
        .catch(err => {
          message.error('Interface error: set_threshold' + err);
        })
    });
  }
}

const Threshold = Form.create()(ThresholdForm);
export default Threshold;