import React from 'react';
import { httpPost } from '../../http';
import { message, Modal, Input, Form, Select } from 'antd';

const FormItem = Form.Item;
const { Option } = Select;


class AddFormContainer extends React.Component {
  state = {};
  render() {
    const { modalVisibleAdd, form, modalTitleAdd, handleModalVisibleAdd, getGoodsList, initData } = this.props;
    const handleOk = () => {
      form.validateFields((err, fieldsValue) => {
        if (err) return;
        form.resetFields();
        let submitValue = {
          id: initData.id,
          ...fieldsValue
        };
        if (!initData.id) {
          httpPost("/api/v1/goods/create", JSON.stringify(submitValue))
            .then(res => {
              return res.json();
            })
            .then(data => {
              if (data.code === 0) {
                handleModalVisibleAdd();
                getGoodsList();
              } else {
                message.error(data.msg);
              }
            })
            .catch(err => {
              message.error('Interface error: add_good');
            }
            )
        } else {
          httpPost("/api/v1/goods/update", JSON.stringify(submitValue))
            .then(res => {
              return res.json();
            })
            .then(data => {
              if (data.code === 0) {
                handleModalVisibleAdd();
                getGoodsList();
              } else {
                message.error(data.msg);
              }
            })
            .catch(err => {
              message.error('Interface error: update_good');
            }
            )
        }
      });
    };
    
    return (
      <Modal
        destroyOnClose
        title={modalTitleAdd}
        visible={modalVisibleAdd}
        width={400}
        okText="Save"
        onOk={handleOk}
        onCancel={() => handleModalVisibleAdd()}

      >
        <Form layout="vertical" hideRequiredMark>
          <FormItem label='Name'>
            {form.getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: 'Please input name.',
                },
              ],
              initialValue: initData.name,
            })(<Input disabled={initData.user_id ? true : false} />)}
          </FormItem>
          <FormItem label='SN'>
            {form.getFieldDecorator('item_number', {
              rules: [
                {
                  required: true,
                  message: 'Please input SN.',
                },
              ],
              initialValue: initData.item_number,
            })(<Input />)}
          </FormItem>
          
          <FormItem label='Number'>
            {form.getFieldDecorator('number', {
              rules: [
                {
                  required: true,
                  message: 'Please input number.',
                },
              ],
              initialValue: initData.number,
            })(<Input />)}
          </FormItem>
          <FormItem label='Description'>
            {form.getFieldDecorator('description', {
              rules: [
                {
                  required: true,
                  message: 'Please input description.',
                },
              ],
              initialValue: initData.description,
            })(<Input />)}
          </FormItem>
        </Form>
      </Modal>
    )
  }
}

const AddForm = Form.create()(AddFormContainer);
export default AddForm;