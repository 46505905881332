import React, { Component } from 'react';

import { PageHeader } from 'antd';

import Contact from './contact';
import Threshold from "./threshold";
import "./setting.css"

export default class Setting extends Component {
  state = {
    is_admin: sessionStorage.getItem("role") === "admin" ? true : false
  };

  componentDidMount() {
  }

  render() {
    const isAdminLink = this.state.is_admin
      ?
      <Threshold />
      : 
      ""

    return (
      <div className="setting">
        <PageHeader title="Setting" />

        <Contact />

        {isAdminLink}

      </div>
    )
  }
}
