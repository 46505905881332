import React, { Component } from 'react';

import { Select, DatePicker, Button, message } from 'antd';
import moment from 'moment';
import 'moment/locale/fr';

import { httpPost } from "http/index.js";
import Line from "./line";
import "./chart.css";

moment.locale('fr');
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD HH:mm:ss';
const zeroFormat = 'YYYY-MM-DD 00:00:00';
const timeFormat = 'HH:mm';

class Chart extends Component {
  state = {
    date: "",
    select: "Temperature",
    chartData: {},
  }
  componentWillMount() {
    const match = this.props.match;

    // const date = moment("2021-07-16 00:00:00").utc().format(dateFormat);
    const date = moment().format(dateFormat);

    this.setState({
      deviceID: match.params.deviceID,
      date: date,
    })


  }

  componentDidMount() {
    this.getTemperatureHistory();
  }

  render() {
    return (
      <div className="chart">
        <div className="wrappedSearch">
          <span style={{ marginLeft: 60 }}>{this.state.chartData.Name}:</span>

          <Select
            defaultValue="Temperature"
            onChange={this.onSelectChange}
            style={{ width: 120, marginLeft: 20 }}
          >
            <Option key="Temperature" value="Temperature">Temperature</Option>
            <Option key="Humidity" value="Humidity">Humidity</Option>
            <Option key="Methane" value="Methane">Methane</Option>
          </Select>

          <DatePicker
            defaultValue={moment(this.state.date, dateFormat)}
            onChange={this.onDateChange}
            style={{ marginLeft: 20 }}
          />

          <Button
            onClick={this.onSearch}
            style={{ marginLeft: 20 }}
            icon="search"
          >Search</Button>
        </div>

        <Line data={this.state.chartData} key={this.state.chartData.random} />

      </div>
    )
  }

  onSelectChange = (value) => {
    this.setState({
      select: value
    })
  }

  onDateChange = (value) => {
    const date = value.format(dateFormat);
    this.setState({
      date: date
    })
  }
  
  onSearch = () => {
    const select = this.state.select;
    if (select === "Temperature") {
      this.getTemperatureHistory();
    } else if (select === "Humidity") {
      this.getHumidityHistory();
    } else if ( select === "Methane") {
      this.getMethaneHistory();
    }
  }
  
  formateData = (data) => {
    data["random"] = Math.random();
    data["title"] = data["Name"] + "-" + this.state.select
    
    const newXdata = data.Xdata.map(item => moment.utc(item, timeFormat).local().format(timeFormat));
    data["Xdata"] = newXdata

    return data
  }
  // const date = moment("2021-07-16 06:10:00").utc().format(dateFormat);
  getTemperatureHistory = () => {
    const zeroDate = moment(this.state.date).format(zeroFormat);
    const zeroUTCDate = moment(zeroDate).utc().format(dateFormat);

    let submitValue = {
      DeviceId: this.state.deviceID,
      Date: zeroUTCDate
    };
    httpPost("/api/v1/instance/get_temperature_history", JSON.stringify(submitValue))
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (data.code === 0) {
          //formate data
          const formateData = this.formateData(data.data)

          this.setState({
            chartData: formateData,
          })
        } else {
          message.error('Interface error: get_temperature_history1');
        }
      })
      .catch(err => {
        message.error('Interface error: get_temperature_history2',err);
      }
      )
  }

  getHumidityHistory = () => {
    const zeroDate = moment(this.state.date).format(zeroFormat);
    const zeroUTCDate = moment(zeroDate).utc().format(dateFormat);

    let submitValue = {
      DeviceId: this.state.deviceID,
      Date: zeroUTCDate
    };
    httpPost("/api/v1/instance/get_humidity_history", JSON.stringify(submitValue))
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (data.code === 0) {
          //formate data
          const formateData = this.formateData(data.data)

          this.setState({
            chartData: formateData,
          })
        } else {
          message.error('Interface error: get_humidity_history');
        }
      })
      .catch(err => {
        message.error('Interface error: get_humidity_history');
      }
      )
  }

  getMethaneHistory = () => {
    const zeroDate = moment(this.state.date).format(zeroFormat);
    const zeroUTCDate = moment(zeroDate).utc().format(dateFormat);

    let submitValue = {
      DeviceId: this.state.deviceID,
      Date: zeroUTCDate
    };
    httpPost("/api/v1/instance/get_methane_history", JSON.stringify(submitValue))
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (data.code === 0) {
          //formate data
          const formateData = this.formateData(data.data)

          this.setState({
            chartData: formateData,
          })
        } else {
          message.error('Interface error: get_methane_history');
        }
      })
      .catch(err => {
        message.error('Interface error: get_methane_history');
      }
      )
  }
}

export default Chart;