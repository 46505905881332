import React, { Component } from 'react'
import {Switch, Route, HashRouter} from 'react-router-dom'
import Login from 'components/Login/Login'
import SubRouter from 'components/Router/SubRouter'

import { ConfigProvider } from 'antd';
import frFR from 'antd/es/locale/fr_FR';

class Routers extends Component{
    render(){
        return (
          <ConfigProvider locale={frFR}>
            <HashRouter>
                <Switch>
                    <Route exact path="/login" component={ Login } />
                    <Route path="/" component={SubRouter}  />
                </Switch>
            </HashRouter>
          </ConfigProvider>
        );
    }
}

export default Routers