/**
 * Created by luosi on 2020/8/12.
 */
import React from 'react';
import { Table, Divider, Popconfirm, message, Icon } from 'antd';
import { httpGet, httpDelete } from '../../http';

import AddForm from './addForm';
import './device.css'

class DeviceList extends React.Component {
  state = {
    modalVisibleAdd: false,
    modalTitleAdd: "",
    editValues: {},
    data: []
  };

  componentDidMount() {
    this.getDeviceList();
  };
  getDeviceList = () => {
    httpGet("/api/v1/device/get_all_devices")
      .then(res => {
        return res.json();
      })
      .then(data => {
        this.setState({
          data: data.data,
        })
      })
      .catch(err => {
        message.error('Interface error: get_all_devices');
      }
      )
  };
  handleModalVisibleAdd = (flag, title, record) => {
    this.setState({
      modalVisibleAdd: !!flag,
      modalTitleAdd: title,
      editValues: record || {},
    });

  };
  delete_confirm = (id) => {
    httpDelete("/api/v1/device/delete", JSON.stringify({ id: id }))
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (data.code === 0) {
          message.info('Delete Success!');
          this.getDeviceList();
        }
      })
      .catch(err => {
        message.error('Interface error: delete_device');
      }
      )
  };

  columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Device ID',
      dataIndex: 'device_id',
      key: 'device_id',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Company',
      dataIndex: 'company_name',
      key: 'company_name',
    },
    {
      title: <Icon type="plus-circle" style={{ fontSize: 22, cursor: 'pointer' }}
        onClick={() => this.handleModalVisibleAdd(true, 'Add Device', {})}
      />,
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <span>
          {/* <a onClick={() => this.handleModalVisibleAdd(true, 'Edit Device', record)}>Edit</a>
          <Divider type="vertical" /> */}
          <Popconfirm placement="top" title="Are you sure to delete this device?"
            onConfirm={() => this.delete_confirm(record.id)} okText="Yes" cancelText="No">
            <a href="#!;">Delete</a>
          </Popconfirm>
        </span>
      ),
    },
  ];

  render() {
    const parentMethodsAdd = {
      handleModalVisibleAdd: this.handleModalVisibleAdd,
      getDeviceList: this.getDeviceList
    };
    return (
      <div className="device">
        <Table rowKey={record => record.device_id} columns={this.columns} dataSource={this.state.data} />
        <AddForm {...parentMethodsAdd} modalVisibleAdd={this.state.modalVisibleAdd} modalTitleAdd={this.state.modalTitleAdd}
          initData={this.state.editValues}/>
      </div>
    )
  }
}

export default DeviceList;