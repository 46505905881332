import React, { Component } from 'react';

// import echarts from 'echarts/lib/echarts';
import * as echarts from 'echarts/lib/echarts.js'
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/toolbox';
import 'echarts/lib/component/grid';

export default class Line extends Component {
  state = {

    //   xData: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    //   yData: [60, 132, 101, 134, 90, 230, 210],


    xData: this.props.data.Xdata,
    yData: this.props.data.Ydata,
    title: this.props.data.title

  }
  componentWillMount() {
    const clientWidth = document.body.clientWidth;
    this.setState({
      searchBoxWidth: clientWidth * 0.8
    })
  }

  componentDidMount() {
    // init
    var myChart = echarts.init(document.getElementById('wrappedChart'));
    // draw chart
    myChart.setOption({
      title: {
        text: this.state.title,
        x: 'center'
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: [""]
      },
      toolbox: {
        feature: {
          // magicType: {
          //   type: ['line', 'bar'],
          //   title: {
          //     line: 'Passer au diagramme linéaire',
          //     bar: "Passer à l'histogramme"
          //   }
          // },
        }
      },
      xAxis: 
        {
          type: 'category',
          boundaryGap: false,
          data: this.state.xData
        }
      ,
      yAxis: 
        {
          type: 'value',
          axisLine:{
            show: true
          }
        }
      ,
      series: {
        data: this.state.yData,
        type: "line"
      }
    });
  }
  render() {
    return (
      <div className="wrappedChart" id="wrappedChart" style={{ width: this.state.searchBoxWidth, height: 500, marginTop: 30 }} ></div>
    )
  }
}
