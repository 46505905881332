import React, { Component } from 'react';

import { Form, Input, Button, Descriptions, message } from 'antd';

import { httpGet, httpPost } from "http/index.js";
const FormItem = Form.Item;

export class ContactForm extends Component {
  state = {
    email: "",
    sms: "",
  }

  componentDidMount() {
    this.getContact();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const initData = this.state;
    return (

      <div className="setContact">
        <Descriptions style={{ marginLeft: 25 }}>
          <Descriptions.Item>Contact Details for Alerts</Descriptions.Item>
        </Descriptions>
        <Form
          onSubmit={this.submitContact}
          labelCol={{ span: 4 }} wrapperCol={{ span: 14 }}
          style={{ width: 500, position: 'relative' }}
        >
          <FormItem label='Email'>
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: 'The input is not valid E-mail.',
                },
                {
                  required: true,
                  message: 'Veuillez saisir le nom.',
                },
              ],
              initialValue: initData.email,
            })(<Input placeholder="Veuillez saisir le nom." />)}
          </FormItem>
          <FormItem label='SMS'>
            {getFieldDecorator('sms', {
              rules: [
                {
                  required: true,
                  message: 'Veuillez saisir le numéro.',
                },
                {
                  pattern: new RegExp('^[0-9]*$'),
                  message: 'Veuillez saisir le numéro.',
                }
              ],
              initialValue: initData.sms,
            })(<Input placeholder="Veuillez saisir le numéro." />)}
          </FormItem>
          <FormItem style={{ position: 'absolute', top: 63, left: 435 }}>
            <Button type="primary" htmlType="submit" >
              Save Contact
            </Button>
          </FormItem>
        </Form>
      </div>
    )
  }

  getContact = () => {
    httpGet("/api/v1/instance/get_contact")
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (data.code === 0) {
          this.setState({
            email: data.data.Email,
            sms: data.data.SMS
          })
        } else {
          message.error('Interface error: get_contact');
        }
      })
      .catch(err => {
        message.error('Interface error: get_contact');
      })
  }

  submitContact = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) return;
      let submitValue = {
        Email: fieldsValue.email,
        SMS: fieldsValue.sms
      };
      httpPost("/api/v1/instance/set_contact", JSON.stringify(submitValue))
        .then(res => {
          return res.json();
        })
        .then(data => {
          if (data.code === 0) {
            this.getContact();
            message.success('Success');
          } else {
            message.error(data.msg);
          }
        })
        .catch(err => {
          message.error('Interface error: set_contact');
        })
    });
  }
}

const Contact = Form.create()(ContactForm);
export default Contact;